import { branch, compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NativeAction } from '../../../services/native-action';
import { getUserAttributes, sendApplicationData } from '../../../store/reducers/user-attributes/actions';
import { getValidations } from '../../../store/reducers/validations';
import { getPoints, getRewardCards } from '../../../store/reducers/points-and-awards';
import { getUserMarketingPreferences } from '../../../store/reducers/account/actions';
import { withLoader } from '../../with-loader';
import { withEmbeddedContext } from '../with-embedded-context';
import { withDefaultContent } from './with-default-content';
import { withEmbeddedContent } from './with-embedded-content';
import { withScroll } from './with-scroll';
import { withRequiredRedirect } from './with-required-redirect';

export const withContent = compose(
  connect(
    state => ({
      loading: state.userAttributes.loading
    }),
    {
      getUserAttributes,
      getValidations,
      getPoints,
      getRewardCards,
      getUserMarketingPreferences,
      sendApplicationData
    }
  ),
  withRouter,
  withEmbeddedContext,
  branch(({ isEmbedded }) => !isEmbedded, withDefaultContent),
  branch(({ isEmbedded }) => isEmbedded, withEmbeddedContent),
  withRequiredRedirect,
  lifecycle({
    componentDidMount() {
      const { processRequiredActivityRedirect } = this.props;

      // redirect user to the required flow if it is not completed
      processRequiredActivityRedirect();
    },
    componentDidUpdate(prevProps) {
      const {
        loading,
        location: { pathname }
      } = this.props;

      // A message notifies native apps to disable their own loading spinner.
      if (!loading) {
        NativeAction.trigger('web_init_finished', { pathname });
      }
    }
  }),
  withScroll,
  withLoader
);
