import { compose, lifecycle, withHandlers, withProps, withPropsOnChange, withState } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { withLoadingHandlers } from '../../../../../components/with-loader';
import { insertVarIntoString } from '../../../../../helpers/insert-var-into-string';
import { inputMethods } from './methods';

//This is the key name of the title in item, that returned from API
const VALUE_NAME = 'value';

export const withDatasetAutocomplete = Component =>
  compose(
    connect(state => ({ attributes: state.userAttributes })),
    withRouter,
    withLoadingHandlers(false),
    withProps(({ match }) => ({
      identifier: match.params.identifier,
      valueName: VALUE_NAME
    })),
    withState('autocompleteItems', 'setAutocompleteItems', []),
    withState('fullItemsList', 'setFullItemsList', []),
    withState(
      'disabled',
      'setDisabled',
      ({ attributes, identifier, userAttributeName }) =>
        !ObjectShim.getNested(attributes, insertVarIntoString(userAttributeName, { identifier }))
    ),
    withState('error', 'setError', null),
    withPropsOnChange(['type'], ({ type }) => ({
      inputMethods: inputMethods[type]
    })),
    withHandlers({
      getItems:
        ({ turnOnLoading, turnOffLoading, datasetKey, valueName, setError, setDisabled, limit, inputMethods }) =>
        async value => {
          try {
            turnOnLoading();

            const response = await inputMethods.get({ datasetKey, value, limit });

            if (response.ok) {
              const data = await response.json();

              return inputMethods.map({ data, valueName });
            }
          } catch (e) {
            setError(e.message);
            setDisabled(true);
            // eslint-disable-next-line no-console
            console.warn(e.message);
          } finally {
            turnOffLoading();
          }
        }
    }),
    withHandlers({
      onAutoCompleteChange:
        ({ getItems, setAutocompleteItems }) =>
        async value => {
          const items = await getItems(value);
          setAutocompleteItems(items);
        },
      onChooseAutocompleteItem:
        ({ onChange, setAutocompleteItems, setDisabled, getItems }) =>
        () =>
        async value => {
          setDisabled(false);
          onChange(value);

          //fetching data on selecting item
          // value is an object with value key or a string - depends on the input type: string for dataset, object for introducer
          const items = await getItems(value.value ?? value);

          setAutocompleteItems(items);
        }
    }),
    withHandlers({
      onValueChange:
        ({ onChange, onAutoCompleteChange, setDisabled }) =>
        value => {
          setDisabled(true);
          onChange(value);
          onAutoCompleteChange(value);
        }
    }),
    withPropsOnChange(['onAutoCompleteChange'], ({ onAutoCompleteChange, time = 500 }) => ({
      onAutoCompleteChange: debounce(onAutoCompleteChange, time)
    })),
    lifecycle({
      async componentDidMount() {
        const { getItems, setAutocompleteItems, value } = this.props;
        const allItems = await getItems(value);
        setAutocompleteItems(allItems);
      }
    })
  )(Component);
