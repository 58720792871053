import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withPropsOnChange, withStateHandlers } from 'recompose';
import { getKnowledgeBase } from '../../store/reducers/content';
import { resetNavKnowledgeLink } from '../../store/reducers/navigation';
import { withLoader, withLoadingHandlers } from '../../components/with-loader';
import { CONTENT_HOME_PAGE_TYPE } from '../../helpers/knowledgebase/category-filter';
import { filterKnowledgeBaseByCategory } from '../../helpers/knowledgebase';
import { DEFAULT_CATEGORY_KEY } from '../../helpers/knowledgebase/constants';
import { withKnowledgeBaseDisplayCondition } from './with-display-condition';
import { withPostsCategoryFilter } from './with-posts-category-filter';

export const withKnowledgeBase = compose(
  connect(null, { getKnowledgeBase, resetNavKnowledgeLink }),
  withLoadingHandlers(true),
  withStateHandlers(
    ({
      filter = DEFAULT_CATEGORY_KEY,
      topicFilter = DEFAULT_CATEGORY_KEY,
      isPersonalisationTurnedOn = true,
      knowledgeBase = [],
      fullKnowledgeBase = { ignoredDisplayCondition: [], checkedParsedCondition: [] }
    }) => ({
      filter,
      topicFilter,
      isPersonalisationTurnedOn
    }),
    {
      handleFilterChange: () => value => ({ filter: value }),
      handleTopicFilterChange: () => value => ({ topicFilter: value }),
      handleKnowledgeBaseChange: () => value => ({ knowledgeBase: value }),
      handleFullKnowledgeBaseChange: () => value => ({ fullKnowledgeBase: value }),
      handleWithPersonalisation:
        ({ isPersonalisationTurnedOn }) =>
        () => ({ isPersonalisationTurnedOn: !isPersonalisationTurnedOn })
    }
  ),
  withPostsCategoryFilter(CONTENT_HOME_PAGE_TYPE.ARTICLES),
  withKnowledgeBaseDisplayCondition,
  withHandlers({
    refreshKnowledgeBaseData:
      ({
        turnOnLoading,
        turnOffLoading,
        handleKnowledgeBaseChange,
        fullKnowledgeBase,
        resetNavKnowledgeLink,
        isPersonalisationTurnedOn
      }) =>
      () => {
        const { checkedParsedCondition, ignoredDisplayCondition } = fullKnowledgeBase;
        const actualTopics = isPersonalisationTurnedOn ? checkedParsedCondition : ignoredDisplayCondition;

        handleKnowledgeBaseChange(actualTopics);
        resetNavKnowledgeLink(actualTopics);
      }
  }),
  withPropsOnChange(['filter', 'knowledgeBase'], ({ filter, knowledgeBase }) => {
    return { knowledgeBaseTopics: filterKnowledgeBaseByCategory({ filter, knowledgeBase }) };
  }),
  lifecycle({
    async componentDidMount() {
      const {
        getKnowledgeBase,
        resetNavKnowledgeLink,
        filterTopics,
        handleKnowledgeBaseChange,
        handleFullKnowledgeBaseChange,
        isPersonalisationTurnedOn,
        turnOffLoading
      } = this.props;

      const knowledgeBase = await getKnowledgeBase();
      const filteredKnowledgeBase = await filterTopics(knowledgeBase);

      const { checkedParsedCondition, ignoredDisplayCondition } = filteredKnowledgeBase;
      const actualTopics = isPersonalisationTurnedOn ? checkedParsedCondition : ignoredDisplayCondition;

      handleFullKnowledgeBaseChange(filteredKnowledgeBase);
      handleKnowledgeBaseChange(actualTopics);
      resetNavKnowledgeLink(actualTopics);

      turnOffLoading();
    }
  }),
  withLoader
);
