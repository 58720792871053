import React from 'react';
import { compose } from 'recompose';
import { HeadingVMPlan } from '../../../widgets/vm-plan-widget/components/heading';
import { HeadingVMMortgage } from '../../../widgets/vm-mortgage-widget/heading';
import { HeadingVMPoints } from '../../../widgets/points-widget/components/heading';
import { withMABHeader } from '../../../widgets/mab-import-portfolio/components/header';
import { PageActionsSection } from '../../page-actions-section';
import {
  withHeader,
  withSplitHeader,
  Icon,
  Body,
  Heading,
  SubHeading,
  Category,
  withHeaderWrapper
} from './components';

const Component = React.memo(
  ({ heading, subheading, icon = false, children, edit = false, category, headingClassName, headerButtons = [] }) => (
    <>
      {icon && <Icon name={icon} />}
      <Body>
        {children}
        {category && <Category>{category}</Category>}
        {heading && <Heading className={headingClassName}>{heading}</Heading>}
        {subheading && <SubHeading>{subheading}</SubHeading>}
        {headerButtons.length ? <PageActionsSection actionsList={headerButtons} /> : null}
      </Body>
      {edit}
    </>
  )
);

export const DefaultHeader = compose(withHeader, withSplitHeader, withHeaderWrapper)(Component);

const headings = {
  'vm-plan': compose(withHeader, withHeaderWrapper)(HeadingVMPlan),
  'what-is-within-reach': compose(withHeader, withHeaderWrapper)(HeadingVMMortgage),
  'vm-points': compose(withHeader, withHeaderWrapper)(HeadingVMPoints),
  'mab-import-portfolio': compose(withHeader, withHeaderWrapper, withMABHeader)(Component)
};

export const Header = React.memo(props => {
  if (props.name && headings[props.name]) {
    const CustomHeader = headings[props.name];

    return <CustomHeader {...props} />;
  }

  return <DefaultHeader {...props} />;
});
