import React from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { compose, lifecycle, branch, withProps, renderComponent, withState } from 'recompose';
import { sortByWeight } from '../../helpers/array';
import styles from '../../styles/widgets/digital-coach-widget/digital-coach.module.scss';
import { CoachMessage } from '../../components/coach-message';
import { InputGroup } from '../../components/inputs/input-group';
import { withCustomSpinner } from '../../components/with-loader';
import { enableCoachLoader, getNextActivity, getSpecificActivity } from '../../store/reducers/digital-coach';
import { UserService } from '../../services/user-servcie';
import {
  TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION,
  TYPE_BUTTON_ACTION_RELOAD,
  TYPE_CONTENT_LINK,
  TYPE_ORIGIN_POLL,
  TYPE_ORIGIN_RATE_APP,
  TYPE_SKIP_SECTION,
  TYPE_FAQ_MODAL
} from '../../helpers/digital-coach/constants';
import { useConfirm } from '../../components/layout/popups/confirm';
import { isModuleCompleted } from '../../helpers/custom-page/is-completed';
import { withEmbeddedContext } from '../../components/layout/with-embedded-context';
import { withCancellableHandlers } from '../../pages/custom-page/with-cancellable-handlers';
import { FAQModalButton } from './buttons/faq-modal';
import { DigitalCoachDisplayController } from './display-controller';
import { Action, ReloadAction, CustomAction, ContentLink } from './actions';
import { RateAppButton } from './buttons/rate';
import { SimplePoll } from './simple-poll';
import { SkipSectionAction } from './buttons/skip-section';

export const DigitalCoachAction = compose(
  branch(({ actionType }) => actionType === TYPE_ORIGIN_RATE_APP, renderComponent(RateAppButton)),
  branch(({ actionType }) => actionType === TYPE_BUTTON_ACTION_RELOAD, renderComponent(ReloadAction)),
  branch(({ actionType }) => actionType === TYPE_BUTTON_ACTION_CALL_CUSTOM_ACTION, renderComponent(CustomAction)),
  branch(({ actionType }) => actionType === TYPE_ORIGIN_POLL, renderComponent(SimplePoll)),
  branch(({ actionType }) => actionType === TYPE_SKIP_SECTION, renderComponent(SkipSectionAction)),
  branch(({ actionType }) => actionType === TYPE_CONTENT_LINK, renderComponent(ContentLink)),
  branch(({ actionType }) => actionType === TYPE_FAQ_MODAL, renderComponent(FAQModalButton))
)(Action);

const DigitalCoachWidgetComponent = React.memo(
  ({ activity: { digitalCoachMessage: message, digitalCoachActions: actions, options }, isMounted }) => {
    const [confirm, handleConfirm] = useConfirm({
      props: {
        acceptType: 'major',
        declineType: 'minor'
      }
    });

    return (
      <>
        <DigitalCoachDisplayController type={options?.slideUp} isMounted={isMounted}>
          <>
            {message && <CoachMessage html={message} />}
            {actions && (
              <InputGroup type='coach'>
                {sortByWeight(actions).map(action => (
                  <DigitalCoachAction key={uuid('digital-coach-action')} onConfirm={handleConfirm} {...action} />
                ))}
              </InputGroup>
            )}
          </>
        </DigitalCoachDisplayController>
        {confirm}
      </>
    );
  }
);

const nextMapStateToProps = state => {
  const activity = state.digitalCoach.nextActivity;
  const { forcedLoading, loading } = state.digitalCoach;
  const { activityKey, digitalCoachActions } = activity;

  // no need to call getNextActivity in componentDidUpdate in case actionType is simplePoll because
  // it is needed to have possibility to send status update without updating activity
  const isPoll = digitalCoachActions?.some(({ actionType }) => actionType === TYPE_ORIGIN_POLL);

  return {
    activity,
    loading: forcedLoading ? forcedLoading : loading,
    isCompleted: activityKey && isModuleCompleted(state.statusData, activityKey) && !isPoll
  };
};

const StaticDigitalCoachWidget = compose(
  withProps(() => {
    const {
      ACCOUNT: { DIGITAL_COACH_ACTIVITY }
    } = window.CONFIG;

    return {
      activity: DIGITAL_COACH_ACTIVITY
    };
  })
)(DigitalCoachWidgetComponent);

export const DigitalCoachWidget = compose(
  connect(nextMapStateToProps, { getNextActivity, enableCoachLoader }),
  withState('isMounted', 'setMounted', false),
  withEmbeddedContext,
  withCancellableHandlers({
    getNextActivityCancellable:
      ({ getNextActivity }) =>
      () =>
        getNextActivity()
  }),
  lifecycle({
    async componentDidMount() {
      const { getNextActivityCancellable, setMounted, isEmbedded, postResizeMessage, withLoader, enableCoachLoader } =
        this.props;

      if (withLoader) {
        enableCoachLoader();
      }
      await getNextActivityCancellable();

      setMounted(true);

      if (isEmbedded) {
        postResizeMessage();
      }
    },
    async componentDidUpdate() {
      const { getNextActivity, isCompleted } = this.props;

      if (isCompleted) {
        await getNextActivity();
      }
    }
  }),
  branch(() => UserService.hasAccount(), renderComponent(StaticDigitalCoachWidget)),
  withCustomSpinner({ className: styles.spinner })
)(DigitalCoachWidgetComponent);

const specificMapStateToProps = state => {
  const activity = state.digitalCoach.specificActivity;
  const { forcedLoading, loading } = state.digitalCoach;
  const { activityKey, digitalCoachActions } = activity;

  // no need to call getNextActivity in componentDidUpdate in case actionType is simplePoll because
  // it is needed to have possibility to send status update without updating activity
  const isPoll = digitalCoachActions?.some(({ actionType }) => actionType === TYPE_ORIGIN_POLL);

  return {
    activity,
    loading: forcedLoading ? forcedLoading : loading,
    isCompleted: activityKey && isModuleCompleted(state.statusData, activityKey) && !isPoll
  };
};

export const SpecificDigitalCoachWidget = compose(
  connect(specificMapStateToProps, { getSpecificActivity }),
  withState('isMounted', 'setMounted', false),
  lifecycle({
    async componentDidMount() {
      const { getSpecificActivity, setMounted, pageIdentifier } = this.props;

      await getSpecificActivity(pageIdentifier);
      setMounted(true);
    },
    async componentDidUpdate() {
      const { getSpecificActivity, isCompleted, pageIdentifier } = this.props;

      if (isCompleted) {
        await getSpecificActivity(pageIdentifier);
      }
    }
  }),
  withCustomSpinner({ className: styles.spinner })
)(DigitalCoachWidgetComponent);
