import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useCarouselController from '@packages/hooks/core/use-carousel-controller';
import { AffiliateIcon } from '../../../../components/icons/affiliate-icon';
import styles from '../../../../styles/widgets/carousel/custom-carousel.module.scss';
import { sortBlocks } from '../../../../helpers/stories/stories';
import { StoryPage } from './carousel-page';
import { SwipeableWrapper } from './swipeable-wrapper';

export const CustomStoryCarousel = React.memo(({ items = [], beforeChange, reportStatus, storySlug }) => {
  const onStoryRead = useCallback(() => reportStatus('read'), [reportStatus]);
  const { handlers, state } = useCarouselController({ items, config: { beforeChange } });
  const { pages, activePage, isFirst, isLast, ariaLive, animationDirection } = state;
  const { handlePrev, handleNext, onFocus, onBlur } = handlers;
  const pagesWithSortedBlocks = useMemo(
    () => pages.map(({ displayConfig, ...rest }) => ({ ...rest, blocksToShow: sortBlocks(displayConfig) })),
    [pages]
  );
  const hiddenDescription = useMemo(
    () =>
      pagesWithSortedBlocks[activePage]?.pageDescription ??
      `Slide ${activePage + 1} of ${pagesWithSortedBlocks.length}`,
    [activePage, pagesWithSortedBlocks]
  );

  return (
    <section id={storySlug} className={styles.carousel} aria-roledescription='carousel' aria-label={storySlug}>
      <div className={styles['carousel-inner']}>
        <div className={styles['carousel-controls']}>
          <AffiliateIcon
            name='prev-slide'
            onClick={handlePrev}
            onFocus={onFocus}
            onBlur={onBlur}
            ariaDisabled={isFirst}
            ariaControls={`${storySlug}-items`}
            className={classnames(styles['carousel-control'], styles['carousel-previous-control'])}
          />
          <AffiliateIcon
            name='next-slide'
            onClick={handleNext}
            onFocus={onFocus}
            onBlur={onBlur}
            ariaDisabled={isLast}
            ariaControls={`${storySlug}-items`}
            className={classnames(styles['carousel-control'], styles['carousel-next-control'])}
          />
        </div>
        <div id={`${storySlug}-items`} className={styles['carousel-items']} aria-live={ariaLive}>
          <div className={styles['hidden-description']}>{hiddenDescription}</div>
          <SwipeableWrapper onSwipedRight={handlePrev} onSwipedLeft={handleNext}>
            {pagesWithSortedBlocks.map((page, index) => (
              <StoryPage
                key={page.id}
                page={page}
                isLast={isLast}
                isActive={index === activePage}
                animationDirection={animationDirection}
                onStoryRead={onStoryRead}
                handlePrev={handlePrev}
                handleNext={handleNext}
              />
            ))}
          </SwipeableWrapper>
        </div>
      </div>
    </section>
  );
});

CustomStoryCarousel.displayName = 'CustomStorySlider';

CustomStoryCarousel.propTypes = {
  storySlug: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  beforeChange: PropTypes.func,
  reportStatus: PropTypes.func
};
